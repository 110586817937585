import React from "react"
import SODInfoSectionStyle from "./SODInfoSectionStyle.module.styl"
import Img from "gatsby-image"

const SodInfoSection = ({ sodInfoImageSection }) => {
  return (
    <section className={SODInfoSectionStyle.sod_info_section}>
      <div className={SODInfoSectionStyle.intro_section_content}>
        <Img
          className={SODInfoSectionStyle.intro_img}
          fluid={sodInfoImageSection[0].node.childImageSharp.fluid}
        />
        <div className={SODInfoSectionStyle.intro_text}>
          <span>Сигнално-охранителната дейност (СОД)</span> , предоставена от
          „Спешъл Сикюрити Груп“ ООД е обезпечена чрез постоянна комуникация
          между нашите патрули и диспечерите в денонощният дежурен център.
          Централата за мониторинг и практиките, които са въведени, осигуряват
          най-добро изпълнение на задълженията по охрана на различните видове
          обекти. Служителите на фирмата са оборудвани с нужните екипировка и
          автомобили, които улесняват бързата реакция и покритие на различните
          райони.
        </div>
      </div>
    </section>
  )
}

export default SodInfoSection
