import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import SodTemplate from "../modules/services/SOD/SOD.template"
import { graphql } from "gatsby"

const SignalnoOhranitelnaDeinost = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Сигнално-охранителна дейност"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <SodTemplate
        sodHeroSectionImage={data.sodHeroSectionImage.edges}
        sodInfoImageSection={data.sodInfoImageSection.edges}
      />
    </Layout>
  )
}

export default SignalnoOhranitelnaDeinost
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    sodHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "sodHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    sodInfoImageSection: allFile(
      filter: { relativeDirectory: { eq: "sodInfoImageSection" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
