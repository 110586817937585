import React from "react"
import SodAdvantagesSectionStyl from "./SODAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const SodAdvantagesSection = props => {
  return (
    <section className={SodAdvantagesSectionStyl.sod_advantages}>
      <div className={SodAdvantagesSectionStyl.sod_advantages_content}>
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={1} advantage={"Патрулна охрана"} />{" "}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={2}
              advantage={"Най-добро покритие на област Ямбол"}
            />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={3} advantage={"Бърза реакция до 2 минути"} />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={4} advantage={"Въоражен патрул"} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SodAdvantagesSection
