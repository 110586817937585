import React from "react"
import SODHeroSectionStyle from "./SODHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const SodHeroSection = ({sodHeroSectionImage}) => {
  return (
    <section className={SODHeroSectionStyle.sod_hero_section}>
      <Img
        className={SODHeroSectionStyle.image}
        fluid={sodHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={SODHeroSectionStyle.title_wrapper}>
        <h1>Сигнално-охранителна дейност</h1>
      </div>
    </section>
  )
}

export default SodHeroSection
