import React from "react"
import SodHeroSection from "./SODHeroSection/SODHeroSection"
import SodInfoSection from "./SODInfoSection/SODInfoSection"
import SodAdvantagesSection from "./SODAdvantagesSection/SODAdvantagesSection"
import SodSliderSection from "./SODSliderSection/SODSliderSection"

const SodTemplate = ({ sodHeroSectionImage, sodInfoImageSection }) => {
  return (
    <>
      <SodHeroSection sodHeroSectionImage={sodHeroSectionImage} />
      <SodInfoSection sodInfoImageSection={sodInfoImageSection} />
      <SodAdvantagesSection />
      <SodSliderSection />
    </>
  )
}

export default SodTemplate
